<template>
  <v-card
    elevation="0"
    class="markdown__card">
    <vue-showdown
      class="markdown__file"
      :markdown="source"/>
  </v-card>
</template>

<script>
export default {
  name: 'CyMarkdownCard',
  props: {
    sourceText: {
      type: Object,
      required: true,
    },
  },
  computed: {
    source () {
      return this.sourceText[this.$i18n.locale]
    },
  },
}
</script>

<style lang="scss" scoped>
.markdown__file {
  padding: 32px;

  ::v-deep {
    blockquote,
    h1,
    h2,
    h3,
    h4,
    p,
    ul {
      margin-top: 16px;
      margin-bottom: 0;
    }

    h1,
    h2,
    h3,
    h4 {
      font-weight: $font-weight-bolder;
      line-height: $line-height-heading;
    }

    h1 {
      margin-bottom: 20px;
      font-size: $font-size-xl;

      &:first-child {
        margin-top: 0;
      }
    }

    h2 {
      padding-top: 20px;
      border-top: 1px solid cy-get-color("primary", "light-4");
      font-size: $font-size-h3;
    }

    h3 {
      margin-top: 32px;
      font-size: $font-size-xl;
    }

    h4 {
      font-size: $font-size-lg;
    }

    a {
      color: cy-get-color("secondary");
    }
  }
}

.markdown__card {
  max-width: 700px;
  margin: 60px auto;
  padding-right: 40px;
  padding-left: 40px;
}
</style>
